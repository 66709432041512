var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$auth.isAuthenticated && !_vm.$auth.loading
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "align-center", staticStyle: { height: "100vh" } },
            [
              _c("v-col", { attrs: { xs: "12", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-wrap justify-center align-center mt-2 black--text",
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "350px" } },
                      [
                        _c("v-img", {
                          staticClass: "mb-14",
                          attrs: {
                            contain: "",
                            src: require("@/assets/Nuvolos-transparent.svg"),
                          },
                        }),
                        _c("seamless-access-button", {
                          staticClass: "mb-4",
                          attrs: {
                            redirect: this.$route?.query?.targetUrl
                              ? this.$route.query.targetUrl
                              : "/",
                          },
                        }),
                        _c("google-oauth-btn", {
                          attrs: {
                            redirect: this.$route?.query?.targetUrl
                              ? this.$route.query.targetUrl
                              : "/",
                          },
                        }),
                        this.$route.query.error
                          ? _c(
                              "v-alert",
                              { staticClass: "mt-4", attrs: { type: "error" } },
                              [
                                _vm._v(
                                  _vm._s(this.$route.query.error_description)
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-8 align-center" },
                          [
                            _c("v-divider"),
                            _c("span", { staticClass: "caption px-2" }, [
                              _vm._v("OR"),
                            ]),
                            _c("v-divider"),
                          ],
                          1
                        ),
                        _c(
                          "v-form",
                          {
                            staticClass: "mt-7 w-100",
                            attrs: { onSubmit: "return false;" },
                            on: {
                              submit: function ($event) {
                                return _vm.checkLoginType(_vm.email)
                              },
                            },
                            model: {
                              value: _vm.valid,
                              callback: function ($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid",
                            },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "font-weight-light",
                              staticStyle: { "font-size": "13px" },
                              attrs: {
                                placeholder: "Nuvolos Account Email",
                                id: "nv-email",
                                rules: [_vm.rules.nonEmptyUsername],
                                outlined: "",
                                dense: "",
                                "e2e-login-input": "",
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _vm.checkLoginTypeError
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { text: "", type: "error" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v("Error")]
                                        ),
                                        _vm._v(
                                          " We encountered an error. Please check the system status or reach out to us using the button below. "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "text-decoration-underline",
                                            attrs: {
                                              light: "",
                                              outlined: "",
                                              color: "red",
                                            },
                                            on: { click: _vm.showIntercomChat },
                                          },
                                          [_vm._v("Help")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$auth.error &&
                            _vm.$auth.error.message &&
                            _vm.$auth.error.message
                              .toLowerCase()
                              .includes("expiration")
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { text: "", type: "error" },
                                  },
                                  [
                                    _vm._v(
                                      " There was a login error! If you continue to see this message, please check if your system time is correctly set. "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-n2 text-none",
                                attrs: {
                                  type: "submit",
                                  outlined: "",
                                  block: "",
                                  disabled: !_vm.valid,
                                  loading: _vm.checkingLoginMethod,
                                  "e2e-signin-email": "",
                                },
                              },
                              [_vm._v(" Sign-in with e-mail ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "mt-8" }, [
                          _c("div", { staticClass: "mb-2 text-center" }, [
                            _c(
                              "a",
                              {
                                staticClass: "black--text",
                                staticStyle: {
                                  "text-decoration": "none",
                                  "font-size": "13px",
                                },
                                on: { click: _vm.getEmailHelp },
                              },
                              [_vm._v(" Don't remember your sign-up e-mail? ")]
                            ),
                          ]),
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "a",
                              {
                                staticClass: "black--text",
                                staticStyle: {
                                  "text-decoration": "none",
                                  "font-size": "13px",
                                },
                                on: { click: _vm.getAccountHelp },
                              },
                              [_vm._v("Don't have an account?")]
                            ),
                          ]),
                          _vm.showEmailHelp
                            ? _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _vm.failedEmail
                                    ? _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "No account found for " +
                                              _vm._s(_vm.failedEmail) +
                                              "."
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "my-4",
                                      attrs: { type: "info" },
                                    },
                                    [
                                      _vm._v(
                                        ' Please check the invitation e-mail you received from Nuvolos and use the e-mail address it was sent to. You might also be able to log in directly using the "Access through your institution" button above. '
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "caption mt-2" }, [
                                    _vm._v(
                                      " If you have trouble accessing your account, you can "
                                    ),
                                    _c(
                                      "a",
                                      { on: { click: _vm.showIntercomChat } },
                                      [_vm._v("ask for help")]
                                    ),
                                    _vm._v(" . "),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showAccountHelp
                            ? _c(
                                "div",
                                { staticClass: "mt-2" },
                                [
                                  _c("v-alert", { attrs: { type: "info" } }, [
                                    _vm._v(
                                      " Students accessing Nuvolos require an invitation from their course administrator to create an account. "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [_c("main-hero", { staticClass: "mr-md-15" })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "500",
                value: _vm.showWAYFChange,
                persistent: "",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "info" } },
                        [_vm._v("info")]
                      ),
                      _vm._v(" Updated Institution Selector "),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("p", [
                        _vm._v("We have updated the institution selector:"),
                      ]),
                      _c("v-img", {
                        staticClass: "my-2",
                        attrs: {
                          src: require("@/assets/wayfinder-change.png"),
                        },
                      }),
                      _c("p", [
                        _vm._v(" Please search for the "),
                        _c("b", [
                          _vm._v("full institution name (with accents)"),
                        ]),
                        _vm._v(
                          " on the next screen. This needs to be done only once. "
                        ),
                      ]),
                      _c("p", [_vm._v("Thank you for your understanding!")]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.SATOSALogin()
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("full-screen-loader")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }