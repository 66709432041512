var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 1057 693" },
      attrs: {
        version: "1.1",
        id: "Hero",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 1057 693",
        "xml:space": "preserve",
      },
    },
    [
      _c(
        "g",
        { staticClass: "st0", attrs: { id: "Versioning" } },
        [
          _c("path", {
            staticClass: "st1",
            attrs: { d: "M798,323h4v207h-4V323z" },
          }),
          _c("circle", {
            staticClass: "st2",
            attrs: { cx: "800", cy: "367", r: "9" },
          }),
          _c("circle", {
            staticClass: "st2",
            attrs: { cx: "800", cy: "407", r: "9" },
          }),
          _c("circle", {
            staticClass: "st2",
            attrs: { cx: "800", cy: "447", r: "9" },
          }),
          _c("circle", {
            staticClass: "st2",
            attrs: { cx: "800", cy: "487", r: "9" },
          }),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_1_",
                gradientUnits: "userSpaceOnUse",
                x1: "579.5597",
                y1: "24.0319",
                x2: "579.5597",
                y2: "236.4319",
                gradientTransform: "matrix(1 0 0 -1 220.3903 556.3319)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff", "stop-opacity": "0" },
                attrs: { offset: "0.2" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff", "stop-opacity": "0" },
                attrs: { offset: "0.8" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("rect", {
            staticClass: "st3",
            attrs: { x: "786", y: "319.9", width: "27.9", height: "212.4" },
          }),
        ],
        1
      ),
      _c(
        "g",
        { staticClass: "st4", attrs: { id: "Code" } },
        [
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M187,440h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C184,441.3,185.3,440,187,440z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M139,440h37c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-37c-1.7,0-3-1.3-3-3v-4C136,441.3,137.3,440,139,440z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M269,425h39c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-39c-1.7,0-3-1.3-3-3v-4C266,426.3,267.3,425,269,425z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M254,425h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C251,426.3,252.3,425,254,425z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M218,425h25c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-25c-1.7,0-3-1.3-3-3v-4C215,426.3,216.3,425,218,425z",
            },
          }),
          _c("path", {
            staticClass: "st5",
            attrs: {
              d: "M139,425h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C136,426.3,137.3,425,139,425z",
            },
          }),
          _c("path", {
            staticClass: "st6",
            attrs: {
              d: "M154,395h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C151,396.3,152.3,395,154,395z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M275,410h38c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-38c-1.7,0-3-1.3-3-3v-4C272,411.3,273.3,410,275,410z",
            },
          }),
          _c("path", {
            staticClass: "st7",
            attrs: {
              d: "M260,410h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C257,411.3,258.3,410,260,410z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M139,395h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C136,396.3,137.3,395,139,395z",
            },
          }),
          _c("path", {
            staticClass: "st6",
            attrs: {
              d: "M139,410h110c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3H139c-1.7,0-3-1.3-3-3v-4C136,411.3,137.3,410,139,410z",
            },
          }),
          _c("path", {
            staticClass: "st7",
            attrs: {
              d: "M139,320h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C136,321.3,137.3,320,139,320z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M218,320h37c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-37c-1.7,0-3-1.3-3-3v-4C215,321.3,216.3,320,218,320z",
            },
          }),
          _c("path", {
            staticClass: "st8",
            attrs: {
              d: "M139,335h111c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3H139c-1.7,0-3-1.3-3-3v-4C136,336.3,137.3,335,139,335z",
            },
          }),
          _c("path", {
            staticClass: "st7",
            attrs: {
              d: "M139,380h111c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3H139c-1.7,0-3-1.3-3-3v-4C136,381.3,137.3,380,139,380z",
            },
          }),
          _c("path", {
            staticClass: "st5",
            attrs: {
              d: "M139,350h39c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-39c-1.7,0-3-1.3-3-3v-4C136,351.3,137.3,350,139,350z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M189,350h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C186,351.3,187.3,350,189,350z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M204,350h25c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-25c-1.7,0-3-1.3-3-3v-4C201,351.3,202.3,350,204,350z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M240,350h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C237,351.3,238.3,350,240,350z",
            },
          }),
          _c("path", {
            staticClass: "st8",
            attrs: {
              d: "M261,380h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C258,381.3,259.3,380,261,380z",
            },
          }),
          _c("path", {
            staticClass: "st6",
            attrs: {
              d: "M139,365h68c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-68c-1.7,0-3-1.3-3-3v-4C136,366.3,137.3,365,139,365z",
            },
          }),
          _c("path", {
            staticClass: "st7",
            attrs: {
              d: "M218,365h38c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-38c-1.7,0-3-1.3-3-3v-4C215,366.3,216.3,365,218,365z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M267,365h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C264,366.3,265.3,365,267,365z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M340,380h4c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3h-4c-1.7,0-3-1.3-3-3v-4C337,381.3,338.3,380,340,380z",
            },
          }),
          _c("path", {
            staticClass: "st2",
            attrs: {
              d: "M282,365h110c1.7,0,3,1.3,3,3v4c0,1.7-1.3,3-3,3H282c-1.7,0-3-1.3-3-3v-4C279,366.3,280.3,365,282,365z",
            },
          }),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_2_",
                gradientUnits: "userSpaceOnUse",
                x1: "265.5",
                y1: "-144",
                x2: "265.5",
                y2: "-9",
                gradientTransform: "matrix(1 0 0 -1 0 308)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff", "stop-opacity": "0" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("rect", {
            staticClass: "st9",
            attrs: { x: "126", y: "317", width: "279", height: "135" },
          }),
        ],
        1
      ),
      _c("g", { staticClass: "st4", attrs: { id: "Grading" } }, [
        _c("path", {
          staticClass: "st10",
          attrs: {
            d: "M1021,189.5l5.9-5.9c0.8-0.8,0.8-2,0-2.8l-1.2-1.2c-0.8-0.8-2-0.8-2.8,0l-5.9,5.9l-5.9-5.9\n\t\tc-0.8-0.8-2-0.8-2.8,0l-1.2,1.2c-0.8,0.8-0.8,2,0,2.8l5.9,5.9l-5.9,5.9c-0.8,0.8-0.8,2,0,2.8l1.2,1.2c0.8,0.8,2,0.8,2.8,0l5.9-5.9\n\t\tl5.9,5.9c0.8,0.8,2,0.8,2.8,0l1.2-1.2c0.8-0.8,0.8-2,0-2.8L1021,189.5z",
          },
        }),
        _c("path", {
          staticClass: "st11",
          attrs: {
            d: "M1012.8,108.9l-9-9c-0.8-0.8-0.8-2,0-2.8l1.3-1.3c0.8-0.8,2-0.8,2.8,0l6,6l13.2-13.2c0.8-0.8,2-0.8,2.8,0\n\t\tl1.3,1.3c0.8,0.8,0.8,2,0,2.8l-16.3,16.3C1014.3,109.4,1013.4,109.4,1012.8,108.9L1012.8,108.9z",
          },
        }),
        _c("path", {
          staticClass: "st11",
          attrs: {
            d: "M1012.8,149.9l-9-9c-0.8-0.8-0.8-2,0-2.8l1.3-1.3c0.8-0.8,2-0.8,2.8,0l6,6l13.2-13.2c0.8-0.8,2-0.8,2.8,0\n\t\tl1.3,1.3c0.8,0.8,0.8,2,0,2.8l-16.3,16.3C1014.3,150.4,1013.4,150.4,1012.8,149.9L1012.8,149.9z",
          },
        }),
      ]),
      _c(
        "g",
        { staticClass: "st4", attrs: { id: "Files" } },
        [
          _c("g", { attrs: { id: "Videos" } }, [
            _c("g", [
              _c("path", {
                staticClass: "st12",
                attrs: {
                  d: "M615.3,483.8h23.4c3.5,0,6.3,2.8,6.3,6.3V523c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC609,486.7,611.8,483.8,615.3,483.8z",
                },
              }),
              _c("g", { attrs: { id: "play" } }, [
                _c("circle", {
                  staticClass: "st13",
                  attrs: { cx: "627.2", cy: "506.7", r: "10.5" },
                }),
                _c("path", {
                  staticClass: "st14",
                  attrs: {
                    id: "Polygon_1",
                    d: "M631.9,506.6l-7.9,4.6V502L631.9,506.6z",
                  },
                }),
              ]),
            ]),
          ]),
          _c("g", { attrs: { id: "Pics" } }, [
            _c("g", [
              _c("path", {
                staticClass: "st15",
                attrs: {
                  d: "M559.8,484.3h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC553.4,487.1,556.3,484.3,559.8,484.3z",
                },
              }),
              _c("path", {
                staticClass: "st16",
                attrs: {
                  id: "Polygon_2",
                  d: "M568.7,499.9l11.3,19.6h-22.6L568.7,499.9z",
                },
              }),
              _c("circle", {
                staticClass: "st17",
                attrs: { cx: "578.7", cy: "496.3", r: "2.2" },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  id: "Polygon_2-2",
                  d: "M577.9,506.6l7.5,12.9h-14.9L577.9,506.6z",
                },
              }),
            ]),
            _c("g", [
              _c("path", {
                staticClass: "st15",
                attrs: {
                  d: "M559.8,541.3h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC553.4,544.1,556.3,541.3,559.8,541.3z",
                },
              }),
              _c("path", {
                staticClass: "st16",
                attrs: {
                  id: "Polygon_2_1_",
                  d: "M568.7,556.9l11.3,19.6h-22.6L568.7,556.9z",
                },
              }),
              _c("circle", {
                staticClass: "st17",
                attrs: { cx: "578.7", cy: "553.3", r: "2.2" },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  id: "Polygon_2-2_1_",
                  d: "M577.9,563.6l7.5,12.9h-14.9L577.9,563.6z",
                },
              }),
            ]),
            _c("g", [
              _c("path", {
                staticClass: "st15",
                attrs: {
                  d: "M558.8,598.3h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC552.4,601.1,555.3,598.3,558.8,598.3z",
                },
              }),
              _c("path", {
                staticClass: "st16",
                attrs: {
                  id: "Polygon_2_2_",
                  d: "M567.7,613.9l11.3,19.6h-22.6L567.7,613.9z",
                },
              }),
              _c("circle", {
                staticClass: "st17",
                attrs: { cx: "577.7", cy: "610.3", r: "2.2" },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  id: "Polygon_2-2_2_",
                  d: "M576.9,620.6l7.5,12.9h-14.9L576.9,620.6z",
                },
              }),
            ]),
          ]),
          _c("g", { attrs: { id: "Texts" } }, [
            _c("g", { attrs: { id: "file-text_2_" } }, [
              _c("path", {
                staticClass: "st18",
                attrs: {
                  d: "M503.3,484h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC497,486.8,499.8,484,503.3,484z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,504.2h23.4c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,504.6,502.9,504.2,503.4,504.2z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,510.8h10.7c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-10.7c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,511.3,502.9,510.8,503.4,510.8z",
                },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  d: "M503.4,494.6h23.4c0.5,0,0.9,0.4,0.9,0.9v2.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-2.9\n\t\t\t\tC502.5,495.1,502.9,494.6,503.4,494.6L503.4,494.6z",
                },
              }),
            ]),
            _c("g", { attrs: { id: "file-text_3_" } }, [
              _c("path", {
                staticClass: "st18",
                attrs: {
                  d: "M503.3,541h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC497,543.8,499.8,541,503.3,541z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,561.2h23.4c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,561.6,502.9,561.2,503.4,561.2z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,567.8h10.7c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-10.7c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,568.3,502.9,567.8,503.4,567.8z",
                },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  d: "M503.4,551.6h23.4c0.5,0,0.9,0.4,0.9,0.9v2.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-2.9\n\t\t\t\tC502.5,552.1,502.9,551.6,503.4,551.6L503.4,551.6z",
                },
              }),
            ]),
            _c("g", { attrs: { id: "file-text_4_" } }, [
              _c("path", {
                staticClass: "st18",
                attrs: {
                  d: "M503.3,598h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC497,600.8,499.8,598,503.3,598z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,618.2h23.4c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,618.6,502.9,618.2,503.4,618.2z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M503.4,624.8h10.7c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-10.7c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC502.5,625.3,502.9,624.8,503.4,624.8z",
                },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  d: "M503.4,608.6h23.4c0.5,0,0.9,0.4,0.9,0.9v2.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-2.9\n\t\t\t\tC502.5,609.1,502.9,608.6,503.4,608.6L503.4,608.6z",
                },
              }),
            ]),
          ]),
          _c("g", { attrs: { id: "Texts-2" } }, [
            _c("g", { attrs: { id: "file-text" } }, [
              _c("path", {
                staticClass: "st18",
                attrs: {
                  d: "M447.3,484h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC441,486.8,443.8,484,447.3,484z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M447.4,504.2h23.4c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC446.5,504.6,446.9,504.2,447.4,504.2z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M447.4,510.8h10.7c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-10.7c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC446.5,511.3,446.9,510.8,447.4,510.8z",
                },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  d: "M447.4,494.6h23.4c0.5,0,0.9,0.4,0.9,0.9v2.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-2.9\n\t\t\t\tC446.5,495.1,446.9,494.6,447.4,494.6L447.4,494.6z",
                },
              }),
            ]),
            _c("g", { attrs: { id: "file-text_1_" } }, [
              _c("path", {
                staticClass: "st18",
                attrs: {
                  d: "M447.3,541h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\t\tC441,543.8,443.8,541,447.3,541z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M447.4,561.2h23.4c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC446.5,561.6,446.9,561.2,447.4,561.2z",
                },
              }),
              _c("path", {
                staticClass: "st19",
                attrs: {
                  d: "M447.4,567.8h10.7c0.5,0,0.9,0.4,0.9,0.9v1.9c0,0.5-0.4,0.9-0.9,0.9h-10.7c-0.5,0-0.9-0.4-0.9-0.9v-1.9\n\t\t\t\tC446.5,568.3,446.9,567.8,447.4,567.8z",
                },
              }),
              _c("path", {
                staticClass: "st14",
                attrs: {
                  d: "M447.4,551.6h23.4c0.5,0,0.9,0.4,0.9,0.9v2.9c0,0.5-0.4,0.9-0.9,0.9h-23.4c-0.5,0-0.9-0.4-0.9-0.9v-2.9\n\t\t\t\tC446.5,552.1,446.9,551.6,447.4,551.6L447.4,551.6z",
                },
              }),
            ]),
          ]),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_3_",
                gradientUnits: "userSpaceOnUse",
                x1: "322.3597",
                y1: "-87.3681",
                x2: "322.3597",
                y2: "75.1319",
                gradientTransform: "matrix(1 0 0 -1 220.3903 556.3319)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff", "stop-opacity": "0" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("rect", {
            staticClass: "st20",
            attrs: { x: "438.3", y: "481.2", width: "208.9", height: "162.5" },
          }),
        ],
        1
      ),
      _c(
        "g",
        { staticClass: "st4", attrs: { id: "Data" } },
        [
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M437.9,274.4h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC431.6,277.2,434.4,274.4,437.9,274.4z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M455,296.9c0,2.5-0.4,4.3-1.2,5.5s-2.1,1.8-3.8,1.8c-1.6,0-2.9-0.6-3.7-1.9s-1.3-3.1-1.3-5.5\n\t\t\t\tc0-2.5,0.4-4.4,1.2-5.6s2.1-1.8,3.8-1.8c1.6,0,2.9,0.6,3.7,1.9S455,294.4,455,296.9z M448,296.9c0,1.8,0.2,3,0.5,3.8\n\t\t\t\ts0.8,1.1,1.5,1.1s1.2-0.4,1.5-1.2s0.5-2,0.5-3.8s-0.2-3-0.5-3.8s-0.8-1.1-1.5-1.1s-1.2,0.4-1.5,1.1S448,295.1,448,296.9z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M483.9,274.4h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC477.6,277.2,480.4,274.4,483.9,274.4z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M501,296.9c0,2.5-0.4,4.3-1.2,5.5s-2.1,1.8-3.8,1.8c-1.6,0-2.9-0.6-3.7-1.9s-1.3-3.1-1.3-5.5\n\t\t\t\tc0-2.5,0.4-4.4,1.2-5.6s2.1-1.8,3.8-1.8c1.6,0,2.9,0.6,3.7,1.9S501,294.4,501,296.9z M494,296.9c0,1.8,0.2,3,0.5,3.8\n\t\t\t\ts0.8,1.1,1.5,1.1s1.2-0.4,1.5-1.2s0.5-2,0.5-3.8s-0.2-3-0.5-3.8s-0.8-1.1-1.5-1.1s-1.2,0.4-1.5,1.1S494,295.1,494,296.9z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M529.9,274.4h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC523.6,277.2,526.4,274.4,529.9,274.4z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M543.5,304h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V304L543.5,304z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M529.8,330.5h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC523.5,333.3,526.3,330.5,529.8,330.5z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M543.5,360h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V360L543.5,360z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M483.8,330.5h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC477.5,333.3,480.3,330.5,483.8,330.5z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M498.5,360h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V360z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M437.8,330.5h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC431.5,333.3,434.3,330.5,437.8,330.5z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M452.5,360h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V360z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M483.8,218.7h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3V225\n\t\t\tC477.5,221.5,480.3,218.7,483.8,218.7z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M497.5,248h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V248z",
                },
              }),
            ]),
          ]),
          _c("g", [
            _c("path", {
              staticClass: "st21",
              attrs: {
                d: "M575.8,330.5h23.4c3.5,0,6.3,2.8,6.3,6.3v32.8c0,3.5-2.8,6.3-6.3,6.3h-23.4c-3.5,0-6.3-2.8-6.3-6.3v-32.8\n\t\t\tC569.5,333.3,572.3,330.5,575.8,330.5z",
              },
            }),
            _c("g", { staticClass: "st22" }, [
              _c("path", {
                staticClass: "st2",
                attrs: {
                  d: "M589.5,360h-3v-8.3v-1.4v-1.5c-0.5,0.5-0.8,0.8-1,1l-1.6,1.3l-1.5-1.8l4.6-3.7h2.5V360L589.5,360z",
                },
              }),
            ]),
          ]),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_4_",
                gradientUnits: "userSpaceOnUse",
                x1: "-735.357",
                y1: "-561.7681",
                x2: "-735.357",
                y2: "-379.9681",
                gradientTransform:
                  "matrix(6.123234e-17 -1 -1 -6.123234e-17 46.3319 -423.057)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ffffff", "stop-opacity": "0" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("rect", {
            staticClass: "st23",
            attrs: { x: "426.3", y: "214.8", width: "181.8", height: "195" },
          }),
        ],
        1
      ),
      _c(
        "g",
        { attrs: { id: "Teacher" } },
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_5_",
                gradientUnits: "userSpaceOnUse",
                x1: "-1543.7795",
                y1: "1783.2205",
                x2: "-1379.4795",
                y2: "1783.2205",
                gradientTransform: "matrix(0 -1 1 0 -1413.5205 -965.4795)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#3381ff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#d6ffa2" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("path", {
            staticClass: "st24",
            attrs: {
              d: "M213,573.3h101c27.6,0,50-22.4,50-50V469c0-27.6,22.4-50,50-50h112.4",
            },
          }),
          _c("g", { attrs: { id: "ui" } }, [
            _c("path", {
              staticClass: "st17 shadow",
              attrs: {
                id: "shadow_5_",
                d: "M71,486h188c5.5,0,10,4.5,10,10v147c0,5.5-4.5,10-10,10H71c-5.5,0-10-4.5-10-10V496\n\t\t\tC61,490.5,65.5,486,71,486z",
              },
            }),
            _c("path", {
              staticClass: "st2",
              attrs: {
                id: "rec",
                d: "M85,519.6h107c5.5,0,10,4.5,10,10V553c0,5.5-4.5,10-10,10H85c-5.5,0-10-4.5-10-10v-23.4\n\t\t\tC75,524,79.5,519.6,85,519.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-2",
                d: "M79,572.6h93c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H79c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC75,574.4,76.8,572.6,79,572.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-3",
                d: "M79,592.6h74c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H79c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC75,594.4,76.8,592.6,79,592.6z",
              },
            }),
            _c("g", { attrs: { id: "bottom_recs" } }, [
              _c("path", {
                staticClass: "st25",
                attrs: {
                  id: "rec-4",
                  d: "M79,622.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H79c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC75,624.4,76.8,622.6,79,622.6L79,622.6z",
                },
              }),
              _c("path", {
                staticClass: "st26",
                attrs: {
                  id: "rec-5",
                  d: "M124,622.7h29c2.2,0,4,1.8,4,4v2.1c0,2.2-1.8,4-4,4h-29c-2.2,0-4-1.8-4-4v-2.1\n\t\t\t\tC120,624.5,121.8,622.7,124,622.7z",
                },
              }),
              _c("path", {
                staticClass: "st27",
                attrs: {
                  id: "rec-6",
                  d: "M168,622.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H168c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC164,624.4,165.8,622.6,168,622.6z",
                },
              }),
            ]),
            _c("g", { staticClass: "st28", attrs: { id: "circles" } }, [
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "225", cy: "500", r: "4" },
              }),
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "240", cy: "500", r: "4" },
              }),
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "255", cy: "500", r: "4" },
              }),
            ]),
          ]),
          _c("g", { attrs: { id: "badge" } }, [
            _c("circle", {
              staticClass: "st2",
              attrs: { cx: "270", cy: "573", r: "20" },
            }),
            _c("path", {
              staticClass: "st29",
              attrs: {
                d: "M270,564l-11,6l11,6l9-4.9v6.9h2v-8 M263,574.2v4l7,3.8l7-3.8v-4l-7,3.8L263,574.2z",
              },
            }),
          ]),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_6_",
                gradientUnits: "userSpaceOnUse",
                x1: "65",
                y1: "-249",
                x2: "65",
                y2: "-119",
                gradientTransform: "matrix(1 0 0 -1 0 308)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#a5fae6" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#3bd0ff" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("circle", {
            staticClass: "st30",
            attrs: { cx: "65", cy: "492", r: "65" },
          }),
          _c("g", [
            _c("g", [
              _c("g", [
                _c("defs", [
                  _c("circle", {
                    attrs: { id: "SVGID_7_", cx: "65", cy: "492", r: "59" },
                  }),
                ]),
                _c("clipPath", { attrs: { id: "SVGID_8_" } }, [
                  _c("use", {
                    staticStyle: { overflow: "visible" },
                    attrs: { "xlink:href": "#SVGID_7_" },
                  }),
                ]),
                _c("g", { staticClass: "st31" }, [
                  _c("image", {
                    staticStyle: {
                      overflow: "visible",
                      "enable-background": "new",
                    },
                    attrs: {
                      width: "400",
                      height: "400",
                      "xlink:href": require("@/assets/teacher.jpg"),
                      transform: "matrix(0.4675 0 0 0.4675 -28.5 400.1295)",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "g",
        { attrs: { id: "Boy_student_2" } },
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_9_",
                gradientUnits: "userSpaceOnUse",
                x1: "-1611.7017",
                y1: "1787.465",
                x2: "-1491.7017",
                y2: "1787.465",
                gradientTransform:
                  "matrix(4.489700e-11 -1 1 4.489700e-11 -1065.965 -1062.7017)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#3381ff" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#d6ffa2" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("path", {
            staticClass: "st32",
            attrs: {
              d: "M897,544H763.3c-27.6,0-50-22.4-50-50v-10c0-27.6-22.4-50-50-50H546",
            },
          }),
          _c("g", { attrs: { id: "ui_3_" } }, [
            _c("path", {
              staticClass: "st17 shadow",
              attrs: {
                id: "shadow_2_",
                d: "M891,444h88c5.5,0,10,4.5,10,10v172c0,5.5-4.5,10-10,10h-88c-5.5,0-10-4.5-10-10V454\n\t\t\tC881,448.5,885.5,444,891,444z",
              },
            }),
            _c("circle", {
              staticClass: "st33",
              attrs: { cx: "935", cy: "611", r: "10" },
            }),
            _c("path", {
              staticClass: "st2",
              attrs: {
                id: "rec-7",
                d: "M905,477.6h60c5.5,0,10,4.5,10,10V511c0,5.5-4.5,10-10,10h-60c-5.5,0-10-4.5-10-10v-23.4\n\t\t\tC895,482,899.5,477.6,905,477.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-8",
                d: "M899,530.6h53c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-53c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC895,532.4,896.8,530.6,899,530.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-9",
                d: "M899,550.6h34c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-34c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC895,552.4,896.8,550.6,899,550.6z",
              },
            }),
            _c("g", { attrs: { id: "bottom_recs-2" } }, [
              _c("path", {
                staticClass: "st25",
                attrs: {
                  id: "rec-10",
                  d: "M899,575.6h28c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-28c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC895,577.3,896.8,575.6,899,575.6z",
                },
              }),
              _c("path", {
                staticClass: "st27",
                attrs: {
                  id: "rec-11",
                  d: "M943,575.8h28c2.2,0,4,1.8,4,4v2.1c0,2.2-1.8,4-4,4h-28c-2.2,0-4-1.8-4-4v-2.1\n\t\t\t\tC939,577.5,940.8,575.8,943,575.8z",
                },
              }),
            ]),
          ]),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "Ellipse_6_copy_1_",
                gradientUnits: "userSpaceOnUse",
                x1: "992",
                y1: "-196",
                x2: "992",
                y2: "-66",
                gradientTransform: "matrix(1 0 0 -1 0 308)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#a5fae6" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#3bd0ff" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("circle", {
            staticClass: "st34",
            attrs: { id: "Ellipse_6_copy", cx: "992", cy: "439", r: "65" },
          }),
          _c("g", [
            _c("g", [
              _c("g", [
                _c("defs", [
                  _c("circle", {
                    attrs: { id: "SVGID_10_", cx: "992", cy: "439", r: "59" },
                  }),
                ]),
                _c("clipPath", { attrs: { id: "SVGID_11_" } }, [
                  _c("use", {
                    staticStyle: { overflow: "visible" },
                    attrs: { "xlink:href": "#SVGID_10_" },
                  }),
                ]),
                _c(
                  "g",
                  {
                    staticClass: "st35",
                    attrs: { transform: "matrix(1 0 0 1 0 -3.051758e-05)" },
                  },
                  [
                    _c("image", {
                      staticStyle: {
                        overflow: "visible",
                        "enable-background": "new",
                      },
                      attrs: {
                        width: "400",
                        height: "400",
                        "xlink:href": require("@/assets/boy2.jpg"),
                        transform:
                          "matrix(-0.4233 0 0 0.4233 1071.1111 358.5556)",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "g",
        { attrs: { id: "Boy_student" } },
        [
          _c(
            "g",
            { attrs: { id: "Vector_Smart_Object_4_" } },
            [
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "SVGID_12_",
                    gradientUnits: "userSpaceOnUse",
                    x1: "-231.4795",
                    y1: "-1053.4795",
                    x2: "-5.7795",
                    y2: "-1053.4795",
                    gradientTransform: "matrix(0 1 -1 0 -336.4795 429.4795)",
                  },
                },
                [
                  _c("stop", {
                    staticStyle: { "stop-color": "#3381ff" },
                    attrs: { offset: "0" },
                  }),
                  _c("stop", {
                    staticStyle: { "stop-color": "#d6ffa2" },
                    attrs: { offset: "1" },
                  }),
                ],
                1
              ),
              _c("path", {
                staticClass: "st36",
                attrs: {
                  d: "M556,418.7h101c27.6,0,50-22.4,50-50v-10c0-27.6,22.4-50,50-50h71c27.6,0,50-22.4,50-50V203",
                },
              }),
            ],
            1
          ),
          _c("g", { attrs: { id: "ui_2_" } }, [
            _c("path", {
              staticClass: "st17 shadow",
              attrs: {
                id: "shadow_1_",
                d: "M782,60h188c5.5,0,10,4.5,10,10v147c0,5.5-4.5,10-10,10H782c-5.5,0-10-4.5-10-10V70\n\t\t\tC772,64.5,776.5,60,782,60z",
              },
            }),
            _c("path", {
              staticClass: "st2",
              attrs: {
                id: "rec-12",
                d: "M796,93.6h107c5.5,0,10,4.5,10,10V127c0,5.5-4.5,10-10,10H796c-5.5,0-10-4.5-10-10v-23.4\n\t\t\tC786,98,790.5,93.6,796,93.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-13",
                d: "M790,146.6h93c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-93c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC786,148.4,787.8,146.6,790,146.6z",
              },
            }),
            _c("path", {
              staticClass: "st11",
              attrs: {
                id: "rec-14",
                d: "M790,166.6h74c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-74c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC786,168.4,787.8,166.6,790,166.6z",
              },
            }),
            _c("g", { attrs: { id: "bottom_recs-3" } }, [
              _c("path", {
                staticClass: "st6",
                attrs: {
                  id: "rec-15",
                  d: "M790,196.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H790c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC786,198.4,787.8,196.6,790,196.6z",
                },
              }),
              _c("path", {
                staticClass: "st27",
                attrs: {
                  id: "rec-16",
                  d: "M835,196.8h29c2.2,0,4,1.8,4,4v2.1c0,2.2-1.8,4-4,4h-29c-2.2,0-4-1.8-4-4v-2.1\n\t\t\t\tC831,198.5,832.8,196.8,835,196.8z",
                },
              }),
              _c("path", {
                staticClass: "st27",
                attrs: {
                  id: "rec-17",
                  d: "M879,196.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H879c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC875,198.4,876.8,196.6,879,196.6z",
                },
              }),
            ]),
            _c("g", { staticClass: "st28", attrs: { id: "circles-2" } }, [
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "936", cy: "74", r: "4" },
              }),
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "951", cy: "74", r: "4" },
              }),
              _c("circle", {
                staticClass: "st18",
                attrs: { cx: "966", cy: "74", r: "4" },
              }),
            ]),
          ]),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "Ellipse_6_copy-2_1_",
                gradientUnits: "userSpaceOnUse",
                x1: "763",
                y1: "178",
                x2: "763",
                y2: "308",
                gradientTransform: "matrix(1 0 0 -1 0 308)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#a5fae6" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#3bd0ff" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("circle", {
            staticClass: "st37",
            attrs: { id: "Ellipse_6_copy-2", cx: "763", cy: "65", r: "65" },
          }),
          _c("g", [
            _c("g", [
              _c("g", [
                _c("defs", [
                  _c("circle", {
                    attrs: { id: "SVGID_13_", cx: "763", cy: "65", r: "59" },
                  }),
                ]),
                _c("clipPath", { attrs: { id: "SVGID_14_" } }, [
                  _c("use", {
                    staticStyle: { overflow: "visible" },
                    attrs: { "xlink:href": "#SVGID_13_" },
                  }),
                ]),
                _c("g", { staticClass: "st38" }, [
                  _c("image", {
                    staticStyle: {
                      overflow: "visible",
                      "enable-background": "new",
                    },
                    attrs: {
                      width: "400",
                      height: "400",
                      "xlink:href": require("@/assets/boy.jpg"),
                      transform: "matrix(0.3789 0 0 0.3789 687.2358 -10.8604)",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "g",
        { attrs: { id: "Girl_student" } },
        [
          _c(
            "g",
            { attrs: { id: "ui_1_" } },
            [
              _c(
                "linearGradient",
                {
                  attrs: {
                    id: "SVGID_15_",
                    gradientUnits: "userSpaceOnUse",
                    x1: "-509.4795",
                    y1: "1665.9795",
                    x2: "-275.4795",
                    y2: "1665.9795",
                    gradientTransform:
                      "matrix(-4.489700e-11 1 1 4.489700e-11 -1162.4795 684.4795)",
                  },
                },
                [
                  _c("stop", {
                    staticStyle: { "stop-color": "#3381ff" },
                    attrs: { offset: "0" },
                  }),
                  _c("stop", {
                    staticStyle: { "stop-color": "#d6ffa2" },
                    attrs: { offset: "1" },
                  }),
                ],
                1
              ),
              _c("path", {
                staticClass: "st39",
                attrs: {
                  d: "M343,180h271c27.6,0,50,22.4,50,50v124c0,27.6-22.4,50-50,50H491.5",
                },
              }),
              _c("path", {
                staticClass: "st14 shadow",
                attrs: {
                  id: "shadow",
                  d: "M179,102h188c5.5,0,10,4.5,10,10v137l20,30H149l20-30V112C169,106.5,173.5,102,179,102z",
                },
              }),
              _c("path", {
                staticClass: "st2",
                attrs: {
                  id: "rec-18",
                  d: "M193,133.6h107c5.5,0,10,4.5,10,10V167c0,5.5-4.5,10-10,10H193c-5.5,0-10-4.5-10-10v-23.4\n\t\t\tC183,138,187.5,133.6,193,133.6z",
                },
              }),
              _c("path", {
                staticClass: "st40",
                attrs: { d: "M169,249v-3h208v3H169z" },
              }),
              _c("path", {
                staticClass: "st11",
                attrs: {
                  id: "rec-19",
                  d: "M187,186.6h93c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4h-93c-2.2,0-4-1.8-4-4v-2.4\n\t\t\tC183,188.4,184.8,186.6,187,186.6z",
                },
              }),
              _c("g", { attrs: { id: "bottom_recs-4" } }, [
                _c("path", {
                  staticClass: "st6",
                  attrs: {
                    id: "rec-20",
                    d: "M187,214.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H187c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC183,216.4,184.8,214.6,187,214.6z",
                  },
                }),
                _c("path", {
                  staticClass: "st27",
                  attrs: {
                    id: "rec-21",
                    d: "M232,214.8h29c2.2,0,4,1.8,4,4v2.1c0,2.2-1.8,4-4,4h-29c-2.2,0-4-1.8-4-4v-2.1\n\t\t\t\tC228,216.5,229.8,214.8,232,214.8z",
                  },
                }),
                _c("path", {
                  staticClass: "st27",
                  attrs: {
                    id: "rec-22",
                    d: "M276,214.6h30.3c2.2,0,4,1.8,4,4v2.4c0,2.2-1.8,4-4,4H276c-2.2,0-4-1.8-4-4v-2.4\n\t\t\t\tC272,216.4,273.8,214.6,276,214.6z",
                  },
                }),
              ]),
              _c("circle", {
                staticClass: "st33",
                attrs: { cx: "273", cy: "113", r: "5" },
              }),
            ],
            1
          ),
          _c(
            "linearGradient",
            {
              attrs: {
                id: "Ellipse_6_copy-3_1_",
                gradientUnits: "userSpaceOnUse",
                x1: "380",
                y1: "146",
                x2: "380",
                y2: "276",
                gradientTransform: "matrix(1 0 0 -1 0 308)",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#a5fae6" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#3bd0ff" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("circle", {
            staticClass: "st41",
            attrs: { id: "Ellipse_6_copy-3", cx: "380", cy: "97", r: "65" },
          }),
          _c("g", [
            _c("g", [
              _c("g", [
                _c("defs", [
                  _c("circle", {
                    attrs: { id: "SVGID_16_", cx: "380", cy: "97", r: "59" },
                  }),
                ]),
                _c("clipPath", { attrs: { id: "SVGID_17_" } }, [
                  _c("use", {
                    staticStyle: { overflow: "visible" },
                    attrs: { "xlink:href": "#SVGID_16_" },
                  }),
                ]),
                _c("g", { staticClass: "st42" }, [
                  _c("image", {
                    staticStyle: {
                      overflow: "visible",
                      "enable-background": "new",
                    },
                    attrs: {
                      width: "400",
                      height: "400",
                      "xlink:href": require("@/assets/girl.jpg"),
                      transform: "matrix(0.3799 0 0 0.3799 299.022 32.9258)",
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("g", { attrs: { id: "Nuvolos" } }, [
        _c("circle", {
          staticClass: "st17 shadow",
          attrs: { id: "shadow_3_", cx: "521.5", cy: "419.5", r: "77.5" },
        }),
        _c("path", {
          staticClass: "st6",
          attrs: {
            d: "M541.5,404c-0.8-2.5-1.9-4.6-3.4-6.3s-3.3-3-5.4-3.8c-2.1-0.8-4.5-1.3-7.1-1.3c-1.2,0-2.4,0.1-3.5,0.3\n\t\tc-1.1,0.2-2.1,0.5-3,0.8c-0.9,0.4-1.7,0.8-2.5,1.2s-1.4,0.9-2,1.4c-1.4,1.1-2.5,2.4-3.4,3.8H511c0,0-0.1-2.5-1-5\n\t\tc-1.3-3.6-4.7-6.2-8.7-6.2c-5.2,0-9.3,4.1-9.3,9.2s4.2,9.2,9.3,9.2c0.3,0,0.5-0.1,0.8-0.1V438c0,1.5,0.4,2.7,1.2,3.6\n\t\tc0.8,0.8,2.1,1.3,3.8,1.3c1.7,0,2.9-0.4,3.8-1.3c0.8-0.8,1.2-2,1.2-3.6v-29.1c0.7-1.4,1.6-2.7,2.7-3.9c0.5-0.5,0.9-0.9,1.5-1.4\n\t\ts1.2-0.9,1.9-1.2c0.7-0.4,1.4-0.7,2.2-0.9c0.8-0.2,1.6-0.4,2.5-0.4c1.5,0,2.9,0.3,4,0.8c1.2,0.5,2.2,1.3,3,2.3s1.5,2.3,1.9,3.9\n\t\ts0.6,3.5,0.6,5.8V438c0,1.5,0.4,2.7,1.2,3.6c0.8,0.8,2.1,1.3,3.8,1.3s2.9-0.4,3.8-1.3c0.8-0.8,1.2-2,1.2-3.6v-25.5\n\t\tC542.6,409.4,542.3,406.5,541.5,404z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }